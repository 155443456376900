import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../SignInButton";
import { SignOutButton } from "../SignOutButton";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../../authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

export const PageLayout = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <div className="relative flex flex-col bg-red">
          <header className="flex flex-row justify-between p-6 text-xl font-bold text-white bg-blue-500">
            <a href="/" className="text-white no-underline">
              Bright Tunes
            </a>
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
          </header>
          <main className="mb-auto">{children}</main>
        </div>
      </MsalProvider>
    </>
  );
};
