const fetchAnnouncementWithRetry = (office, retries) =>{

  const announcement_url = "https://dealtunes-api.azurewebsites.net/api/lastAnnouncement/"+office;

  return fetch(announcement_url)
    .then((response) => response.text())
    .then((data) => {
      if (data) {
        const announcement = JSON.parse(data);
        return announcement[0];
      }
      if (retries > 0) {
        return fetchAnnouncementWithRetry(office, retries - 1);
      }
    });
  }

export default fetchAnnouncementWithRetry;