export const msalConfig = {
    auth: {
      clientId: "b1faf630-7ab6-4744-9339-9974fd101f8b",
      authority: "https://login.microsoftonline.com/6920cccd-0811-4069-beaa-b4a5ac1f25de",
      redirectUri:  window.location.origin //"https://dealtunes-web.azurewebsites.net",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false, 
    }
  };
  
  export const loginRequest = {
   scopes: ["User.Read"]
  };
