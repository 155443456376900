import React from "react";
import { PageLayout } from "../../components/PageLayout";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

const Home = () => {
  return (
    <PageLayout>
      <div className="flex w-1/2 gap-2 mx-auto my-10 text-center flex-column">
        <div className="font-bold ">
          👋 Hello there! You've found yourself at Bright Tunes
        </div>
        <UnauthenticatedTemplate>
          <span>Sign in to do things </span>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <div className="flex flex-col gap-2">
            <div>
              You probably want to <a href="/add">Add a Custom Announcement</a>
            </div>
            <div>
              or you might want to see what{" "}
              <a href="/recent">Recently Played</a>
            </div>
          </div>
        </AuthenticatedTemplate>
      </div>
    </PageLayout>
  );
};

export default Home;
