import React from "react";

const LocalVideo = ({ video }, loop=false) => {

  return (
    <video autoPlay muted loop={loop}>
      <source src={video} type="video/mp4"/>
    </video>
  );
};

export default LocalVideo;
