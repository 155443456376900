import React from "react";
import LocalVideo from "../LocalVideo";
import money_cut_video from '../../videos/money_cut.mov'
import { TrackJS } from 'trackjs';

const Announcement = ({ messageLine1, messageLine2, messageLine3 }) => {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full overflow-hidden -z-1">
        <LocalVideo video={money_cut_video} />
      </div>
      <div className="flex items-center justify-center w-screen h-screen">
        <div className="z-10 w-full px-6 py-10 bg-white bg-opacity-50 border rounded-md">
          <div className="font-bold text-center text-pink-800 opacity-100 m-full top-12 text-9xl">
            <div className="animate-pulse">
              <div>{messageLine1}</div>
              <div>{messageLine2}</div>
              <div>{messageLine3}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Announcement;
