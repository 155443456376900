import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@brighthr/component-button";

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <Button onClick={() => handleLogout(instance)} text="Sign Out"/>
    );
}