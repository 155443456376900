import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Home from "./pages/home";
import AddAnnouncement from "./pages/add_announcement";
import NotFound from "./pages/not_found";
import AnnouncementScreen from "./pages/announcement_screen";
import RecentAnnouncements from "./pages/recent";
import { TrackJS } from 'trackjs';

TrackJS.install({
  token: "e8af43bf2c1f4b0f97d57f4712dc464c",
  application: "dealtunes"
});

function App() {

  return (
    <>
      <UnauthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route exact path="/announcement/:office" element={<AnnouncementScreen />} />
            <Route exact path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
          <BrowserRouter>
            <Routes>
              <Route exact path="/recent" element={<RecentAnnouncements/>}/>
              <Route exact path="/announcement/:office" element={<AnnouncementScreen />} />
              <Route exact path="/add" element={<AddAnnouncement />} />
              <Route exact path="/" element={<Home />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
      </AuthenticatedTemplate>
    </>
  );
}

export default App;
