import React from "react";
import { PageLayout } from "../../components/PageLayout";

const NotFound = () => {
  return (
    <PageLayout>
    <div className="pt-10 text-center flex-column">
      <h1> 404</h1>
      <p>The page you are looking for is missing</p>
      <p className="pt-10 font-semibold"> Pronunciation /ˈmɪsɪŋ/ </p>
      <p className="flex w-1/2 px-6 m-auto text-left flex-column">
        <div className="font-bold">ADJECTIVE</div>(of a thing) not able to be found because it is not
        in its expected place.
        <div>
          <i>‘a quantity of cash has gone missing’</i>
        </div>
        <hr/>
        <div className="py-2">
          Not present or included when expected or supposed to be.
        </div>
        <div>
          <i> ‘you can fill in the missing details later’</i>
        </div>
        <hr/>
        <div  className="py-2">
          (of a person) absent from a place, especially their home, and of
          unknown whereabouts.
        </div>
        <div>
          <i> ‘he has been reported as a missing person’</i>
        </div>
        <hr/>
        <div  className="py-2">
          (of a person) not yet traced or confirmed as alive, but not known
          to be dead, after an accident or during wartime.
        </div>
        <div>
          <i> ‘four soldiers had been wounded and one man was missing’</i>
        </div>
      </p>
    </div>
    </PageLayout>
  );
};

export default NotFound;
