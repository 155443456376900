import React from "react";
import { Formik, Form, Field } from "formik";
import { useMsal } from "@azure/msal-react";
import { PageLayout } from "../../components/PageLayout";
import LocalVideo from "../../components/LocalVideo";
import postNewAnnouncement from "../../services/postNewAnnouncement";

const addToQueue = async (values) => {
  await postNewAnnouncement(values);
};

const AddAnnouncement = () => {
  const { accounts } = useMsal();
  return (
    <PageLayout>
      <div className="p-2 m-2 text-left bg-blue-200 border border-blue-600 rounded">
        <h1 className="p-2 font-bold">Add a Custom Announcement</h1>
        <p className="px-4 pb-2">
          {accounts[0].name}
          you can add a custom announcement here! you can add upto 3 lines of
          text, pick the tune and optionally add a background Video
        </p>
      </div>
      <Formik
        initialValues={{
          messageLine1: "",
          messageLine2: "",
          messageLine3: "",
          tune: "",
          video: "",
          createdBy: accounts[0].name,
          office:"-1"
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          const errors = {};
          if (
            !values.messageLine1 &&
            !values.messageLine2 &&
            !values.messageLine3
          ) {
            errors.messageLine1 = "Cant add an empty announcement!";
          }

          if(values.office === "-1"){
            errors.office = "You must select an office"
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          addToQueue(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, errors }) => (
          <Form>
            <div className="flex flex-row w-1/2 gap-2 p-2 bg-white">
              <div className="flex flex-col w-1/2 gap-2 p-6 text-left border border-blue-500 rounded shadow-lg">
                <label>Message</label>
                <Field
                  type="text"
                  name="messageLine1"
                  maxLength="23"
                  className="p-1 border border-gray-800 rounded"
                />
                <Field
                  type="text"
                  name="messageLine2"
                  maxLength="23"
                  className="p-1 border border-gray-800 rounded"
                />
                <Field
                  type="text"
                  name="messageLine3"
                  maxLength="23"
                  className="p-1 border border-gray-800 rounded"
                />
                <div className="flex flex-col">
                  Tune
                  <Field
                    type="text"
                    name="tune"
                    className="p-1 px-2 border border-gray-800 rounded"
                    placeholder="copy and paste the mp3 filename here"
                  />
                  {errors.messageLine1 ? (
                    <div className="text-red-600">{errors.messageLine1}</div>
                  ) : null}
                </div>

                <div className="flex flex-col">
                  <Field as="select" name="office">
                    <option value="-1">
                      Select an Office To show This Announcement
                    </option>
                    <option value="Manchester">Manchester</option>
                    <option value="Manchester-11">Manchester-11</option>
                    <option value="Dublin">Dublin</option>
                    <option value="Toronto">Toronto</option>
                  </Field>
                  {errors.office ? (
                    <div className="text-red-600">{errors.office}</div>
                  ) : null}

                </div>
                <button
                  type="submit"
                  className="p-4 font-bold text-white bg-blue-500 border border-blue-900 rounded shadow-lg hover:bg-blue-800"
                  disabled={isSubmitting}
                >
                  Add to Queue
                </button>
              </div>

              <div className="flex flex-col w-1/2 p-6 bg-white border border-blue-500 rounded shadow-lg">
                <label>Preview (might look a bit different)</label>
                <div className="relative">
                  <div className="w-full h-full overflow-hidden -z-1">
                    <LocalVideo video="videos/money_cut.mov" />
                  </div>
                  <div className="absolute top-0 flex items-center justify-center w-full h-full">
                    <div className="z-10 w-full px-6 py-2 bg-white bg-opacity-50 border rounded-md">
                      <div className="text-3xl font-bold text-center text-pink-800 opacity-100 m-full top-12">
                        <div className="animate-pulse">
                          <div>{values.messageLine1}</div>
                          <div>{values.messageLine2}</div>
                          <div>{values.messageLine3}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};

export default AddAnnouncement;
