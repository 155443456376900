import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Announcement from "../../components/Announcement";
import fetchAnnouncementWithRetry from "../../services/fetchAnnouncementWithRetry";

const AnnouncementScreen = () => {
  const [announcement, setAnnouncement] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [debugMessage, setDebugMessage] = useState("");
  const { office } = useParams();

  useEffect(() => {
    fetchAnnouncementWithRetry(office, 1)
      .then((data) => {
        if (data) {
          setAnnouncement(data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setDebugMessage("error: " + error.toString());
      });
  }, [office]);
  return (
    <>
      {isLoading && (
        <div className="absolute w-auto min-w-full min-h-full text-6xl text-white bg-black max-w-none">
          {debugMessage}
        </div>
      )}
      {!isLoading && (
        <div className="absolute w-auto min-w-full min-h-full bg-black max-w-none">
          <Announcement
            messageLine1={announcement.MessageLine1}
            messageLine2={announcement.MessageLine2}
            messageLine3={announcement.MessageLine3}
            video={announcement.Video}
          ></Announcement>
          <div className="fixed bottom-0 w-screen p-6 text-3xl text-white bg-blue-600">
            <div className="flex flex-row justify-between">
              <div>Now Playing: {announcement.Tune}</div>
              <div>{office}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AnnouncementScreen;
