import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/PageLayout";
import getRecentAnnouncements from "../../services/getRecentAnnouncements";

const RecentAnnouncements = () => {
  const [recent, setRecent] = useState([]);

  useEffect(() => {
    getRecentAnnouncements().then((items) => {
      setRecent(items);
    });
  }, []);

  return (
    <>
      <PageLayout>
        <h1 className="px-4">Recent Announcements</h1>
        <div>
          <table className="w-full m-4 table-auto">
            <thead>
              <tr>
                <th>Line 1</th>
                <th>Line 2</th>
                <th>Line 3</th>
                <th>Queued</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              {recent.map((item) => {
                return (
                  <tr>
                    <td>{item.MessageLine1.substring(0, 23)}</td>
                    <td>{item.MessageLine2.substring(0, 23)}</td>
                    <td>{item.MessageLine3.substring(0, 23)}</td>
                    <td>{item.Created}</td>
                    <td>{item.CreatedBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </PageLayout>
    </>
  );
};

export default RecentAnnouncements;
