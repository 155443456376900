const postNewAnnouncement = (values) => {
  return fetch(
    "/api/postNewAnnouncement",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }
  );
};

export default postNewAnnouncement;
